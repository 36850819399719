<template>
  <div ref="observerTarget" class="">
    <div v-if="isLoading">
      <PromotionSkeleton />
    </div>
    <div v-else>
      <PromotionView
        :promos="promos"
        @on-promotion-banner-clicked="onPromotionBannerClicked"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from "vue";
import { useIntersectionObserver } from "@vueuse/core";
import { ref, onMounted, watch, shallowRef } from "#imports";
import PromotionView from "~/section/promotion/PromotionView.vue";
import PromotionSkeleton from "~/section/promotion/PromotionSkeleton.vue";
import type { GetBannerAPIResponse } from "~/types/Banner";
import { getBanners } from "~/api/common/banner";
import type { HomepageModelEvent } from "~/types/Home";

const emits = defineEmits<{
  (e: "on-promotion-banner-clicked", data: HomepageModelEvent): void;
}>();
const promos = shallowRef<GetBannerAPIResponse[]>([]);
const isLoading = ref(true);
const observerTarget = ref(null);
const isVisible = ref(false);

const props = defineProps({
  endPointApi: {
    type: String,
    default: "",
  },
});

const { endPointApi } = toRefs(props);

const { stop } = useIntersectionObserver(
  observerTarget,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

async function fetchBanners() {
  isLoading.value = true;
  const { isSuccess, data } = await getBanners({
    endPointApi: endPointApi.value,
  });

  if (isSuccess && data && data.length) {
    isLoading.value = false;
    promos.value = data.filter((promotion) => {
      if (promotion.locations && promotion.locations.length) {
        const isLocationPromotion = promotion.locations.filter((location) =>
          location.includes("promotions")
        );
        return isLocationPromotion.length > 0;
      }

      return false;
    });
    isVisible.value = false;
    stop();
  }
}

function onPromotionBannerClicked(data: HomepageModelEvent) {
  emits("on-promotion-banner-clicked", data);
}

onMounted(() => {
  watch(isVisible, (newVal) => {
    if (newVal) {
      fetchBanners();
    }
  });
  watch(endPointApi, (newVal) => {
    fetchBanners();
  });
});
</script>

<script lang="ts">
export default {
  name: "HomePromotion",
};
</script>
